<mat-drawer-container [hasBackdrop]="false" class="fx-fill">
	<mat-drawer
		#drawer
		[mode]="'over'"
		[style.max-width.px]="360"
		[style.min-height.%]="100"
		[style.min-width.px]="300">
		<button
			(click)="drawer.toggle()"
			class="drawer-close-btn"
			color="warn"
			mat-icon-button>
			<mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
		</button>

		@switch (panel) {
			@case ('patient') {
				<mat-card appearance="outlined" class="reports-card">
					<mat-toolbar
						class="report-toolbar"
						style="
							min-height: 0 !important;
							max-height: 0 !important;
						">
					</mat-toolbar>

					@if (patient) {
						<mat-card-content style="margin: 8px">
							<div
								class="fx-layout-row-nowrap fx-content-start fx-items-start fx-gap-12">
								<div class="image-place" style="width: 90px">
									<img [src]="getPatientImage()" alt="" />
								</div>
								<div
									class="fx-grow-1 fx-layout-column fx-content-space-between"
									style="height: 90px">
									<div class="ft-patient-link">
										<a
											[routerLink]="
												'/patients/folder/' + patient.id
											"
											>{{ patient.patientName }}</a
										>
									</div>
									<div>
										<span
											style="
												font-size: 12px;
												color: #888888;
											"
											>{{ patient.patientID }}</span
										>
									</div>

									<div class="alerts fx-grow-1">
										{{
											patient.alerts ||
												('NO_ALERTS' | translate)
										}}
									</div>
								</div>
							</div>
							<div
								class="fx-layout-row fx-content-space-between fx-items-center fx-gap-4"
								style="margin-top: 12px">
								<span class="ft-badge-i">{{
									patient.patientSex
								}}</span>
								<span class="ft-badge-i"
									>{{ patient.patientAge }}
									{{ 'Y' | translate }}</span
								>
								<span class="ft-badge-i"
									>{{ patient.imc }} kg/m²</span
								>
								<span class="ft-badge-i"
									>{{ patient.sc }} m²</span
								>
								<span class="fx-grow-1"></span>
							</div>

							@if (patientDetails) {
								<div class="detail-title">
									{{
										'ADDITIONAL_PATIENT_HISTORY' | translate
									}}
								</div>
								<div class="detail-content">
									{{
										patientDetails.medicalHistory?.additionalPatientHistory || '-'
									}}
								</div>
								<div class="detail-title">
									{{ 'ALLERGIES' | translate }}
								</div>
								<div class="detail-content">
									{{
										patientDetails.medicalHistory?.allergies || '-'
									}}
								</div>
								<div class="detail-title">
									{{ 'SPECIALNEEDS' | translate }}
								</div>
								<div class="detail-content">
									{{
										patientDetails.medicalHistory?.specialNeeds || '-'
									}}
								</div>

								<mat-divider></mat-divider>
								<div class="detail-title">
									{{ 'CONTACT' | translate }}
								</div>
								<div class="detail-content">
									<div
										class="contact-item fx-layout-row-nowrap fx-content-start fx-items-center fx-gap-4">
										<mat-icon
											fontIcon="mdi-phone"
											fontSet="mdi"></mat-icon>
										<span>{{
											patientDetails.patient?.phone || '-'
										}}</span>
									</div>
									<div
										class="contact-item fx-layout-row-nowrap fx-content-start fx-items-center fx-gap-4">
										<mat-icon
											fontIcon="mdi-email"
											fontSet="mdi"></mat-icon>
										<span>{{
											patientDetails.patient?.email || '-'
										}}</span>
									</div>
									<div
										class="contact-item fx-layout-row-nowrap fx-content-start fx-items-center fx-gap-4">
										<mat-icon
											fontIcon="mdi-google-maps"
											fontSet="mdi"></mat-icon>
										<span>{{patientDetails.patientAddress?.street }}
											{{ patientDetails.patientAddress?.city }}
											{{patientDetails.patientAddress?.country }}</span>
									</div>
								</div>
							}
						</mat-card-content>
					}
				</mat-card>
			}
			@case ('report') {
				<mat-card
					appearance="outlined"
					class="reports-card fx-layout-column-nowrap">
					<mat-toolbar class="report-toolbar">
						<span>{{ 'CURRENT_REPORT' | translate }}</span>
					</mat-toolbar>

					@if (selectedReport) {
						<div style="padding: 8px 16px; overflow: auto">
							<mat-form-field class="fx-fill-width">
								<mat-label>{{
									'TEMPLATEMODEL' | translate
								}}</mat-label>
								<input
									[formControl]="templateModelControl"
									[matAutocomplete]="modelList"
									[placeholder]="'TEMPLATEMODEL' | translate"
									matInput />
								<mat-autocomplete
									#modelList="matAutocomplete"
									class="auto-complete"
									(optionSelected)="onSelectTemplate($event)"
									[autoActiveFirstOption]="true">
									@for (
										model of filteredTemplateModels;
										track model
									) {
										<mat-option [value]="model.name">{{
											model.name
										}}</mat-option>
									}
								</mat-autocomplete>
								<button
									(click)="
										$event.stopImmediatePropagation();
										searchReportTemplate()
									"
									[disabled]="selectedReport.locked"
									mat-icon-button
									matSuffix>
									<mat-icon
										fontIcon="mdi-magnify"
										fontSet="mdi"></mat-icon>
								</button>
							</mat-form-field>

							<mat-form-field class="fx-fill-width">
								<mat-label>{{
									'TECHNIQUE' | translate
								}}</mat-label>
								<input
									[formControl]="techniqueControl"
									[matAutocomplete]="technique"
									[placeholder]="'TECHNIQUE' | translate"
									matInput />
								<mat-autocomplete
									#technique="matAutocomplete"
									(optionSelected)="onChangeTechnique($event)"
									[autoActiveFirstOption]="true">
									@for (
										technique of filteredTechniques;
										track technique
									) {
										<mat-option [value]="technique">
											<div class="fx-layout-row">
												<span>
													{{ technique.value }}</span
												>
												<span class="fx-grow-1"></span>
												<button
													(click)="
														$event.stopImmediatePropagation();
														editTechnique(technique)
													"
													mat-icon-button>
													<mat-icon
														[style.color]="
															'#2196f3'
														"
														fontIcon="mdi-pencil"
														fontSet="mdi"></mat-icon>
												</button>
												<button
													(click)="
														$event.stopImmediatePropagation();
														deleteTechnique(
															technique
														)
													"
													mat-icon-button>
													<mat-icon
														[style.color]="
															'#ff4f6c'
														"
														fontIcon="mdi-delete"
														fontSet="mdi"></mat-icon>
												</button>
											</div>
										</mat-option>
									}
								</mat-autocomplete>
								<button
									(click)="
										$event.stopImmediatePropagation();
										addTechnique()
									"
									[disabled]="selectedReport.locked"
									mat-icon-button
									matSuffix>
									<mat-icon
										fontIcon="mdi-plus"
										fontSet="mdi"></mat-icon>
								</button>
							</mat-form-field>
							<mat-form-field class="fx-fill-width">
								<mat-label>{{
									'PATHOLOGY' | translate
								}}</mat-label>
								<mat-select
									(selectionChange)="selectPathology()"
									[formControl]="pathologyControl"
									[multiple]="true"
									[placeholder]="'PATHOLOGY' | translate">
									<mat-select-trigger>
										{{
											pathologyControl.value
												? pathologyControl.value[0]
												: ''
										}}

										@if (
											pathologyControl.value?.length > 1
										) {
											<span class="additional-selection">
												(+{{
													pathologyControl.value
														.length - 1
												}}
												{{ 'OTHER1' | translate
												}}{{
													pathologyControl.value
														?.length <= 2
														? ''
														: 's'
												}})
											</span>
										}
									</mat-select-trigger>
									@for (
										pathology of pathologies;
										track pathology
									) {
										<mat-option [value]="pathology.value">{{
											pathology.value
										}}</mat-option>
									}
								</mat-select>

								<button
									(click)="
										$event.stopImmediatePropagation();
										addPathology()
									"
									[disabled]="selectedReport.locked"
									mat-icon-button
									matSuffix>
									<mat-icon
										fontIcon="mdi-plus"
										fontSet="mdi"></mat-icon>
								</button>
							</mat-form-field>
						</div>
					}
				</mat-card>
			}
			@case ('record') {
				<mat-card
					appearance="outlined"
					class="reports-card fx-layout-column-nowrap">
					<mat-toolbar class="report-toolbar">
						<span>{{ 'DICTATIONS' | translate }}</span>
					</mat-toolbar>
					<mat-card-content
						class="fx-grow-1 fx-overflow-auto"
						style="padding: 0 8px">
						@if (selectedReport) {
							<mat-nav-list>
								@for (item of dictations; track item) {
									<mat-list-item
										(click)="showDictation(item)"
										style="
											height: 26px;
											margin-bottom: 2px;
										">
										<mat-icon
											fontIcon="mdi-play-circle"
											fontSet="mdi"
											matListItemIcon></mat-icon>
										<div
											class="fx-layout-row-nowrap fx-content-space-between fx-items-center">
											<h4 matLine>
												{{
													item.recordingDate
														| date
															: 'dd/MM/yyyy HH:mm'
												}}
											</h4>
											<button
												(click)="
													$event.stopPropagation();
													deleteDictation(item)
												"
												mat-icon-button>
												<mat-icon
													fontIcon="mdi-delete"
													fontSet="mdi"></mat-icon>
											</button>
										</div>
									</mat-list-item>
								}

								@if (dictations.length === 0) {
									<mat-list-item
										style="
											height: 26px;
											margin-bottom: 2px;
										">
										<h4 [style.color]="'grey'" matLine>
											{{ 'NO_ITEM' | translate }}
										</h4>
									</mat-list-item>
								}
							</mat-nav-list>
						}
					</mat-card-content>
				</mat-card>
			}
			@case ('key-images') {
				<mat-card
					appearance="outlined"
					class="reports-card fx-layout-column-nowrap">
					<mat-toolbar class="report-toolbar">
						<span>{{ 'KEYIMAGES' | translate }}</span>
					</mat-toolbar>
					<mat-card-content
						class="fx-grow-1 fx-overflow-auto"
						style="padding: 0 8px">
						@if (reportingTaskDetail?.reportingTask) {
							<mat-grid-list [cols]="3" rowHeight="1:1">
								@for (image of selectedImages; track image) {
									<mat-grid-tile>
										<mat-grid-tile-header
											class="fx-layout-row"
											style="
												height: 24px;
												padding: 0 !important;
											">
											<span class="fx-grow-1"></span>
											<button
												(click)="deleteKos(image)"
												color="warn"
												mat-icon-button>
												<mat-icon
													fontIcon="mdi-delete"
													fontSet="mdi"
													style="
														color: inherit;
													"></mat-icon>
											</button>
										</mat-grid-tile-header>
										<img
											(click)="showImage(image)"
											[src]="image.url"
											height="100%"
											style="cursor: pointer"
											alt=""
											width="auto" />
									</mat-grid-tile>
								}
							</mat-grid-list>

							@if (selectedImages.size === 0) {
								<div style="color: #666; padding: 12px">
									{{ 'NO_ITEM' | translate }}
								</div>
							}
						}
					</mat-card-content>
				</mat-card>
			}
			@case ('history') {
				<mat-card
					appearance="outlined"
					class="reports-card fx-layout-column-nowrap">
					<mat-toolbar class="report-toolbar">
						<span>{{ 'HISTORY' | translate }}</span>
					</mat-toolbar>

					<mat-card-content
						class="fx-grow-1 fx-overflow-auto"
						style="padding: 0 8px">
						@if (reportingTasks) {
							<mat-nav-list>
								@for (item of reportingTasks; track item) {
									<mat-list-item
										(click)="
											drawer.close();
											openSelectedReportingTask(
												item.reportingTask,
												false
											)
										"
										[ngClass]="{
											selected:
												item.reportingTask.id ===
												reportingTaskDetail
													?.reportingTask?.id
										}"
										class="reports-list"
										style="border-radius: 12px">
										<mat-icon
											fontIcon="mdi-file-plus"
											fontSet="mdi"
											matListItemIcon
											style="
												margin-right: 8px;
											"></mat-icon>
										<button
											mat-icon-button
											matListItemMeta
											(click)="
												$event.stopPropagation();
												showReport(
													item.reportingTask,
													true
												)
											"
											(mouseenter)="
												showReport(item.reportingTask)
											"
											(mouseleave)="closeDialogReport()">
											<mat-icon
												fontSet="mdi"
												fontIcon="mdi-eye"></mat-icon>
										</button>
										<p matListItemLine>
											{{
												item.reportingTask
													.procedureCodes
											}}
										</p>
									</mat-list-item>
								} @empty {
									<mat-list-item>
										<h4 matListItemLine>
											{{ 'NO_ITEM' | translate }}
										</h4>
									</mat-list-item>
								}
							</mat-nav-list>
						}
					</mat-card-content>
				</mat-card>
			}
			@case ('docs') {
				<mat-card
					appearance="outlined"
					class="reports-card fx-layout-column-nowrap">
					<mat-toolbar class="report-toolbar">
						<span>{{ 'FILES_ATTACHED' | translate }}</span>
					</mat-toolbar>

					<mat-card-content
						class="fx-grow-1 fx-overflow-auto"
						style="padding: 0 8px">
						@if (patientDetails) {
							<div
								class="fx-layout-column-nowrap fx-content-start fx-gap-8">
								@for (
									file of patientDetails.fileElements;
									track file
								) {
									<div
										(click)="openElement(file)"
										class="file-element fx-layout-row-nowrap fx-content-start fx-items-center fx-gap-8">
										<img
											[src]="getFileIcon(file.fileType)"
											alt="" />
										<div class="file-title">
											<span
												[style.font-weight]="'bold'"
												>{{ file.name }}</span
											><br />
											<span
												style="
													font-size: 11px;
													font-style: italic;
												"
												>{{
													file.createdDate
														| date: dateTimeFormat
												}}</span
											>
										</div>
										<span class="fx-grow-1"></span>
										<button
											(click)="
												$event.stopPropagation();
												downloadFile(file)
											"
											color="primary"
											mat-icon-button>
											<mat-icon
												fontIcon="mdi-download"
												fontSet="mdi"></mat-icon>
										</button>
									</div>
								}
							</div>
						}
					</mat-card-content>
				</mat-card>
			}
			@default {}
		}
	</mat-drawer>

	<mat-drawer-content
		class="ft-report-container"
		style="overflow: hidden !important">
		<div
			(click)="getPatient(); panel = 'patient'; drawer.toggle()"
			[matTooltip]="'PATIENT_DETAILS' | translate"
			class="details-btn patient"
			matTooltipPosition="right">
			<mat-icon
				fontIcon="mdi-card-account-details"
				fontSet="mdi"
				style="position: relative; top: 7px; right: 6px"></mat-icon>
		</div>

		<div
			(click)="panel = 'report'; drawer.toggle()"
			[matTooltip]="'CURRENT_REPORT' | translate"
			class="details-btn report"
			matTooltipPosition="right">
			<mat-icon
				fontIcon="mdi-file-cog"
				fontSet="mdi"
				style="position: relative; top: 7px; right: 6px"></mat-icon>
		</div>

		<div
			(click)="panel = 'history'; drawer.toggle()"
			[matTooltip]="'HISTORY' | translate"
			class="details-btn history"
			matTooltipPosition="right">
			<mat-icon
				[matBadge]="historyCount"
				aria-hidden="false"
				fontIcon="mdi-file-restore"
				fontSet="mdi"
				matBadgeColor="warn"
				matBadgeSize="small"
				style="position: relative; top: 7px; right: 6px"></mat-icon>
		</div>

		<div
			(click)="panel = 'record'; drawer.toggle()"
			[matTooltip]="'DICTATIONS' | translate"
			class="details-btn record"
			matTooltipPosition="right">
			<mat-icon
				[matBadge]="dictationsCount"
				fontIcon="mdi-record-player"
				aria-hidden="false"
				fontSet="mdi"
				matBadgeColor="warn"
				matBadgeSize="small"
				style="position: relative; top: 7px; right: 6px"></mat-icon>
		</div>

		<div
			(click)="getPatient(); panel = 'docs'; drawer.toggle()"
			[matTooltip]="'FILES_ATTACHED' | translate"
			class="details-btn docs"
			matTooltipPosition="right">
			<mat-icon
				[matBadge]="filesAttachedCount"
				fontIcon="mdi-folder-open"
				aria-hidden="false"
				fontSet="mdi"
				matBadgeColor="warn"
				matBadgeSize="small"
				style="position: relative; top: 7px; right: 6px"></mat-icon>
		</div>

		<div
			(click)="panel = 'key-images'; drawer.toggle()"
			[matTooltip]="'KEYIMAGES' | translate"
			class="details-btn key-images"
			matTooltipPosition="right">
			<mat-icon
				[matBadge]="keyImagesCount"
				fontIcon="mdi-image-multiple"
				aria-hidden="false"
				fontSet="mdi"
				matBadgeColor="warn"
				matBadgeSize="small"
				style="position: relative; top: 7px; right: 6px"></mat-icon>
		</div>

		<div [class.expand]="patientExpand" class="fx-fill fx-layout-row">
			<div
				class="fx-grow-1"
				[ngStyle]="{
					borderRight: !isHide ? '1px solid lightgrey' : 'none'
				}">
				<div class="nav">
					<mat-toolbar
						color="primary"
						class="fx-height-36 fx-layout-row-nowrap fx-content-end fx-items-center fx-gap-12">
						<div
							class="title fx-grow-1 fx-layout-row-nowrap fx-content-start fx-items-center">
							<mat-icon
								(click)="panel = 'report'; drawer.toggle()"
								fontIcon="mdi-menu"
								fontSet="mdi"
								style="cursor: pointer"></mat-icon>
							@if (reportingTaskDetail?.reportingTask) {
								<span
									>{{
										reportingTaskDetail?.reportingTask
											?.patientName
									}}
									:
									{{
										reportingTaskDetail?.reportingTask
											?.procedureCodes
									}}</span
								>
							}
						</div>
						<div
							class="fx-grow-1 fx-layout-row fx-content-end fx-items-center fx-gap-16">
							@if (selectedReport) {
								<div
									class="fx-layout-row-nowrap fx-content-end fx-items-center fx-gap-8">
									<button
										(click)="startDictation()"
										[class.disabled]="
											reportingTaskDetail?.reportingTask
												?.reportingStatus === 'SIGNED'
										"
										[disabled]="selectedReport.locked"
										[matTooltip]="'DICTATE' | translate"
										[style.color]="
											selectedReport.locked
												? ''
												: '#e12f2f'
										"
										class="btn-top"
										mat-button>
										<mat-icon
											class="toolbar-icon-gradient-dictate"
											fontIcon="mdi-circle"
											fontSet="mdi"></mat-icon>
									</button>
									<mat-divider
										[vertical]="true"
										style="height: 28px"></mat-divider>

									@if (canAssign) {
										<button
											(click)="
												assignPerformer(
													reportingTaskDetail?.reportingTask
												)
											"
											[class.disabled]="
												reportingTaskDetail
													?.reportingTask
													?.reportingStatus ===
												'SIGNED'
											"
											[disabled]="
												reportingTaskDetail
													?.reportingTask
													?.reportingStatus ===
												'SIGNED'
											"
											mat-button>
											<mat-icon
												class="toolbar-icon-gradient"
												fontIcon="mdi-account-arrow-right"
												fontSet="mdi"></mat-icon>
											{{ 'DELEGATE_TASK' | translate }}
										</button>
									}
									@if (hasPermission('verifyReport')) {
										<button
											(click)="validateReport()"
											[class.disabled]="
												selectedReport.locked
											"
											[disabled]="selectedReport.locked"
											mat-button
											class="fw-500">
											<mat-icon
												class="toolbar-icon-gradient-validate"
												fontIcon="mdi-file-certificate"
												fontSet="mdi"></mat-icon>
											{{ 'VALIDATE_REPORT' | translate }}
										</button>
									}
									@if (hasPermission('signReport')) {
										<button
											(click)="signReport()"
											[class.disabled]="
												selectedReport.locked
											"
											[disabled]="selectedReport.locked"
											mat-button
											class="fw-500">
											<mat-icon
												class="toolbar-icon-gradient-sign"
												fontIcon="mdi-file-check"
												fontSet="mdi"></mat-icon>
											{{ 'SIGN_REPORT' | translate }}
										</button>
									}
								</div>
							}
						</div>

						<mat-divider
							[vertical]="true"
							style="height: 28px"></mat-divider>
						<div
							class="fx-grow-1 fx-layout-row-nowrap fx-content-end fx-items-center fx-gap-12">
							@if (reportingTaskDetail?.reportingTask) {
								<div
									class="fx-layout-row-nowrap fx-content-end fx-items-center fx-gap-8">
									<button
										(click)="setToTranscribe()"
										class="fx-grow-1"
										mat-button
										[class.disabled]="
											reportingTaskDetail?.reportingTask
												?.reportingStatus === 'SIGNED'
										"
										[disabled]="
											reportingTaskDetail?.reportingTask
												?.reportingStatus === 'SIGNED'
										">
										<mat-icon
											class="toolbar-icon-gradient-to-transcribe"
											fontIcon="mdi-file-edit-outline"
											fontSet="mdi"></mat-icon>
										{{ 'TO_TRANSCRIBE' | translate }}
									</button>
									<button
										(click)="setToReview()"
										class="fx-grow-1"
										mat-button
										[class.disabled]="
											reportingTaskDetail?.reportingTask
												?.reportingStatus === 'SIGNED'
										"
										[disabled]="
											reportingTaskDetail?.reportingTask
												?.reportingStatus === 'SIGNED'
										">
										<mat-icon
											class="toolbar-icon-gradient-to-review"
											fontIcon="mdi-file-restore"
											fontSet="mdi"></mat-icon>
										{{ 'TO_REVIEW' | translate }}
									</button>
									<button
										(click)="setToValidate()"
										class="fx-grow-1"
										mat-button
										[class.disabled]="
											reportingTaskDetail?.reportingTask
												?.reportingStatus === 'SIGNED'
										"
										[disabled]="
											reportingTaskDetail?.reportingTask
												?.reportingStatus === 'SIGNED'
										">
										<mat-icon
											class="toolbar-icon-gradient-to-validate"
											fontIcon="mdi-check"
											fontSet="mdi"></mat-icon>
										{{ 'TO_VALIDATE' | translate }}
									</button>
									<button
										(click)="setToSign()"
										class="fx-grow-1"
										mat-button
										[class.disabled]="
											reportingTaskDetail?.reportingTask
												?.reportingStatus === 'SIGNED'
										"
										[disabled]="
											reportingTaskDetail?.reportingTask
												?.reportingStatus === 'SIGNED'
										">
										<mat-icon
											class="toolbar-icon-gradient-to-sign"
											fontIcon="mdi-gesture"
											fontSet="mdi"></mat-icon>
										{{ 'TO_SIGN' | translate }}
									</button>

									<button
										(click)="onClose(true)"
										[matTooltip]="'CLOSE' | translate"
										class="m80 fx-grow-1"
										mat-button>
										<mat-icon
											class="toolbar-icon-gradient-close"
											fontIcon="mdi-exit-to-app"
											fontSet="mdi"></mat-icon>
										{{ 'CLOSE' | translate }}
									</button>
								</div>
							}

							<mat-divider
								[vertical]="true"
								style="height: 28px"></mat-divider>
							<button
								class="btn-top"
								[matTooltip]="'PRINTING_HISTORY' | translate"
								mat-button
								(click)="displayPrintingHistory()">
								<mat-icon
									class="toolbar-icon-gradient-print-history"
									fontSet="mdi"
									fontIcon="mdi-printer-eye"></mat-icon>
							</button>
							<mat-divider
								[vertical]="true"
								style="height: 28px"></mat-divider>

							<div
								class="fx-layout-row-nowrap fx-content-end fx-items-center fx-gap-8">
								@if (
									reportingTaskDetail?.instancesAvailable &&
									hasPermission('viewImages')
								) {
									<button
										(click)="openViewer()"
										class="btn-top"
										mat-button
										matTooltip="{{
											'OPEN_IN_VIEWER' | translate
										}}">
										<mat-icon
											class="toolbar-icon-gradient-view"
											fontIcon="mdi-monitor-dashboard"
											fontSet="mdi"></mat-icon>
									</button>
								}

								@if (hasPermission('printReport')) {
									<button
										(click)="printBooklet()"
										class="btn-top"
										mat-button
										matTooltip="{{
											'PRINT_BOOKLET' | translate
										}}">
										<mat-icon
											class="toolbar-icon-gradient-booklet"
											fontIcon="mdi-book-open-blank-variant"
											fontSet="mdi"></mat-icon>
									</button>
								}

								@if (hasPermission('printReport')) {
									@if (
										generalSetting?.reportPrintMode ===
										'CUPS'
									) {
										<button
											[matMenuTriggerFor]="printersMenu"
											class="btn-top"
											mat-button
											matTooltip="{{
												'PRINT_REPORT' | translate
											}}">
											<mat-icon
												class="toolbar-icon-gradient-print"
												fontIcon="mdi-printer"
												fontSet="mdi"></mat-icon>
										</button>
									} @else {
										<button
											(click)="
												printReport(null, 'CHROME')
											"
											class="btn-top"
											mat-button
											matTooltip="{{
												'PRINT_REPORT' | translate
											}}">
											<mat-icon
												class="toolbar-icon-gradient-print"
												fontIcon="mdi-printer"
												fontSet="mdi"></mat-icon>
										</button>
									}
								}

								<button
									(click)="printReport(null, 'CHROME', true)"
									class="btn-top"
									mat-button
									matTooltip="{{
										'PRINT_REPORT_WITH_LINK' | translate
									}}">
									<mat-icon
										class="toolbar-icon-gradient-print"
										fontIcon="mdi-printer-pos-star"
										fontSet="mdi"></mat-icon>
								</button>

								@if (hasPermission('shareReport')) {
									<button
										[matMenuTriggerFor]="shareMenu"
										class="btn-top"
										mat-button
										matTooltip="{{ 'SHARE' | translate }}">
										<mat-icon
											class="toolbar-icon-gradient-share"
											fontIcon="mdi-share-variant"
											fontSet="mdi"></mat-icon>
									</button>
								}

								<mat-menu
									#shareMenu="matMenu"
									overlapTrigger="true">
									<button (click)="sendMail()" mat-menu-item>
										<mat-icon
											[style.color]="'#F44336'"
											fontIcon="mdi-gmail"
											fontSet="mdi"></mat-icon>
										Email
									</button>
									<button (click)="sendSMS()" mat-menu-item>
										<mat-icon
											[style.color]="'#00BCD4'"
											fontIcon="mdi-message-text"
											fontSet="mdi"></mat-icon>
										SMS
									</button>
									<button
										(click)="shareViaPortal()"
										mat-menu-item>
										<mat-icon
											[style.color]="'#5d419f'"
											fontIcon="mdi-monitor-share"
											fontSet="mdi"></mat-icon>
										{{ 'portal_share' | translate }}
									</button>
								</mat-menu>

								@if (hasPermission('viewImages')) {
									<button
										(click)="openImageGallery()"
										class="btn-top"
										mat-button
										matTooltip="{{ 'IMAGES' | translate }}">
										<mat-icon
											class="toolbar-icon-gradient-images"
											[fontIcon]="
												!isHide
													? 'mdi-folder-open'
													: 'mdi-folder'
											"
											fontSet="mdi"></mat-icon>
									</button>
								}
							</div>
						</div>
					</mat-toolbar>

					@if (isHide && !selectedReport?.locked) {
						<button
							[matMenuTriggerFor]="patientDataMenu"
							class="office-menu patient-menu"
							mat-button>
							Patient
						</button>

						<button
							[matMenuTriggerFor]="examDataMenu"
							class="office-menu exam-menu"
							mat-button>
							Exam
						</button>
					}
				</div>

				<mat-menu #patientDataMenu="matMenu">
					@for (item of patientVariables; track item) {
						<button
							(click)="insertVariable(item.key)"
							mat-menu-item>
							{{ item.label }}
						</button>
					}
				</mat-menu>

				<mat-menu #examDataMenu="matMenu">
					@for (item of examVariables; track item) {
						<button
							(click)="insertVariable(item.key)"
							mat-menu-item>
							{{ item.label }}
						</button>
					}
				</mat-menu>

				<mat-menu #printersMenu="matMenu">
					@for (printer of printers; track printer) {
						<div
							(click)="printReport(printer['label'])"
							class="fx-layout-row fx-content-space-between fx-items-center"
							mat-menu-item>
							<span class="fx-grow-1"
								><mat-icon
									fontIcon="mdi-printer"
									fontSet="mdi"></mat-icon>
								{{ printer['label'] }}</span
							>
							<span
								(click)="
									$event.stopPropagation();
									editCount(printer['label'])
								"
								class="print-count"
								>{{ countDefault[printer['label']] }}</span
							>
						</div>
					}
				</mat-menu>

				<div class="editor2">
					@if (selectedReport) {
						<div class="form-mode">
							@if (appType === 'cvis') {
								<mat-stepper headerPosition="top" #stepper>
									<ng-template matStepperIcon="edit">
										<mat-icon
											fontSet="mdi"
											fontIcon="mdi-pencil"></mat-icon>
									</ng-template>
									<ng-template matStepperIcon="done">
										<mat-icon
											fontSet="mdi"
											fontIcon="mdi-check"></mat-icon>
									</ng-template>
									<ng-template
										matStepperIcon="number"
										let-index="index">
										{{ index + 1 }}
									</ng-template>

									<mat-step state="number">
										<ng-template matStepLabel>
											{{
												'PATIENT_AND_SECURITY'
													| translate
											}}
										</ng-template>
										<div style="height: 100%">
											<ft-patient-security
												[workflowItem]="workflowItem"
												[patientData]="patient"
												(nextEvent)="
													stepper.next()
												"></ft-patient-security>
										</div>
									</mat-step>

									<mat-step state="number">
										<ng-template matStepLabel>
											{{ 'PHARMACY' | translate }}
										</ng-template>
										<div style="height: 100%">
											<ft-pharmacy
												[workflowItem]="workflowItem"
												(previousEvent)="
													stepper.previous()
												"
												(nextEvent)="
													stepper.next()
												"></ft-pharmacy>
										</div>
									</mat-step>

									<mat-step state="number">
										<ng-template matStepLabel>{{
											'PROCEDURE_CODE' | translate
										}}</ng-template>
										<ft-reporting-form
											[reportingTask]="
												reportingTaskDetail?.reportingTask
											"
											(previousEvent)="stepper.previous()"
											(generateReportEvent)="
												switchToEditorAndCompile(
													$event
												);
												stepper.next()
											"></ft-reporting-form>
									</mat-step>
									<mat-step state="number">
										<ng-template matStepLabel>{{
											'REPORT' | translate
										}}</ng-template>

										@if (group === 'template') {
											<div
												class="editor-parent fx-fill-height fx-layout-row-nowrap fx-content-space-between fx-gap-4">
												<ft-reporter
													(editorInitialized)="
														editorReady($event)
													"
													[studyInstanceUID]="
														reportingTaskDetail
															?.reportingTask
															?.studyInstanceUID
													"
													[documentTitle]="
														selectedReport.name
													"
													[emptyReport]="reportEmpty"
													[fileId]="
														selectedReport.id +
														'_' +
														selectedReport.templateModelId
													"
													[templateMode]="0"
													[editable]="
														!selectedReport.locked
													"
													[templateModel]="
														selectedReport.templateModelId
													"
													[procedureCode]="
														reportingTaskDetail
															?.reportingTask
															?.procedureCodes
													"
													[procedureType]="
														reportingTaskDetail
															?.reportingTask
															?.examCategory
													"
													[approved]="approved"
													[patientData]="patientData"
													[examData]="examData"
													[ccData]="ccData"
													class="fx-grow-1">
												</ft-reporter>
											</div>
										}
									</mat-step>
								</mat-stepper>
							} @else {
								<div
									class="editor-parent fx-fill-height fx-layout-row-nowrap fx-content-space-between fx-gap-4">
									<ft-reporter
										(editorInitialized)="
											editorReady($event)
										"
										[studyInstanceUID]="
											reportingTaskDetail?.reportingTask
												?.studyInstanceUID
										"
										[documentTitle]="selectedReport.name"
										[emptyReport]="reportEmpty"
										[fileId]="
											selectedReport.id +
											'_' +
											selectedReport.templateModelId
										"
										[templateMode]="0"
										[editable]="!selectedReport.locked"
										[templateModel]="
											selectedReport.templateModelId
										"
										[procedureCode]="
											reportingTaskDetail?.reportingTask
												?.procedureCodes
										"
										[procedureType]="
											reportingTaskDetail?.reportingTask
												?.examCategory
										"
										[approved]="approved"
										[patientData]="patientData"
										[examData]="examData"
										class="fx-grow-1">
									</ft-reporter>
								</div>
							}
						</div>
					}
				</div>
			</div>

			<div id="sidenav" class="image-container">
				<mat-toolbar
					class="report-toolbar fx-layout-row-nowrap fx-content-space-between fx-items-center"
					color="primary"
					style="margin-bottom: 6px">
					<span class="fx-grow-1">{{
						'DICOM_DATA' | translate
					}}</span>
					<button
						color="warn"
						[matTooltip]="'CLOSE' | translate"
						mat-icon-button
						(click)="closeImageGallery()">
						<mat-icon
							[style.color]="'#811040'"
							fontSet="mdi"
							fontIcon="mdi-close"></mat-icon>
					</button>
				</mat-toolbar>

				@if (!isHide) {
					<div class="gallery-container">
						<ft-study-explorer
							(keyImageSelection)="onKeyImageSelection($event)"
							[pacsPatientID]="pacsPatientID"
							[patientID]="patientID"></ft-study-explorer>
					</div>
				}
			</div>

			@if (editorIsReady && appType !== 'cvis') {
				<div
					class="labels fx-layout-row-nowrap fx-content-start fx-items-center">
					<h5>{{ 'LABELS' | translate }}:</h5>
					<div
						class="fx-layout-row-nowrap fx-content-start fx-items-center fx-gap-4"
						style="scale: 0.8">
						<mat-chip-listbox
							class="fx-layout-row fx-content-start fx-items-end">
							@for (label of labels; track label) {
								<mat-chip-option
									[style.background-color]="label['color']"
									(removed)="removeLabel(label)">
									{{ label.value }}
									<button matChipRemove>
										<mat-icon
											fontSet="mdi"
											fontIcon="mdi-close"></mat-icon>
									</button>
								</mat-chip-option>
							}

							@if (!showLabelInput) {
								<button
									mat-button
									(click)="addLabel()"
									color="primary">
									<mat-icon
										fontIcon="mdi-plus"
										fontSet="mdi"></mat-icon>
									{{ 'LABEL' | translate }}
								</button>
							} @else {
								<div
									class="relative fx-layout-column fx-content-end"
									style="padding-bottom: 3px"
									(mouseleave)="showPopup = false">
									@if (showPopup) {
										<ft-labels-popup
											class="absolute"
											[searchKey]="
												labelControl.valueChanges
													| async
											"
											(itemSelected)="
												selectLabel($event)
											"></ft-labels-popup>
									}
									@if (showLabelInput) {
										<input
											(click)="showPopup = true"
											type="text"
											[placeholder]="'SEARCH' | translate"
											class="label-input"
											[formControl]="labelControl" />
									}
								</div>
							}
						</mat-chip-listbox>
					</div>
				</div>
			}
		</div>
	</mat-drawer-content>
</mat-drawer-container>

@if (reportDialogVisible) {
	<div class="report-dialog">
		<ft-report-dialog
			(closeEvent)="onCloseReportPreview($event)"
			[reportingTask]="reportDialogData"></ft-report-dialog>
	</div>
}
